import React from 'react'
import Img from 'gatsby-image'

const AboutClients = ({ text, logos }) => {
  return (
    <div className="pt-6 md:pt-10">
      {text && (
        <div className="pb-20 lg:pb-22">
          <div
            className="rte leading-tight text-2xl lg:text-4xl xl:text-5xl"
            dangerouslySetInnerHTML={{
              __html: text.html,
            }}
          />
        </div>
      )}
      <div className="flex flex-wrap items-end justify-between">
        {logos
          .filter(item => item.logo.localFile)
          .map((item, index) => {
            return (
              <div key={index} className="w-1/2 pb-10">
                {item.logo.localFile && (
                  <Img
                    style={{ maxWidth: '6rem' }}
                    fluid={item.logo.localFile.childImageSharp.fluid}
                    alt={item.logo.alt}
                  />
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default AboutClients
