import React from 'react'
import Img from 'gatsby-image'

const AboutProfiles = ({ profiles, regionGrouped }) => {
  if (regionGrouped === 'True') {
    return (
      <>
        {profiles
          .filter(item => item.region.document)
          .map((item, index) => {
            return (
              <div key={index} className="mb-15 last:mb-10 lg:last:mb-22">
                {item.region.document.data.title && (
                  <div className="flex -ml-gs">
                    <div className="w-full pl-gs pt-5 md:pt-10">
                      <div className="border-b leading-tight text-2xl lg:text-5xl">
                        <h3 className="block pb-2">
                          {item.region.document.data.title}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-wrap -ml-gs">
                  {item.region.document.data.profiles.map((item, index) => {
                    return (
                      <AboutProfile
                        profile={item.profile.document}
                        key={index}
                      />
                    )
                  })}
                </div>
              </div>
            )
          })}
      </>
    )
  } else {
    return (
      <div className="mb-15 last:mb-10 lg:last:mb-22">
        <div className="flex justify-end flex-wrap -ml-gs">
          {profiles.map((item, index) => {
            return <AboutProfile profile={item.node} key={index} />
          })}
        </div>
      </div>
    )
  }
}

export default AboutProfiles

const AboutProfile = ({ profile }) => {
  if (!profile) return null
  return (
    <div className="w-full lg:w-1/2 pl-gs">
      <div className="mb-5 mt-10 leading-tight text-xs lg:text-lg">
        {profile.data.heading && <h4>{profile.data.heading}</h4>}
        {profile.data.subheading && <div>{profile.data.subheading}</div>}
      </div>

      {profile.data.image.localFile && (
        <Img
          fluid={profile.data.image.localFile.childImageSharp.fluid}
          alt={profile.data.image.alt}
          className="mb-6"
        />
      )}

      {profile.data.body.html && (
        <div
          className="rte leading-tight lg:text-lg md:pr-gs lg:pr-ogs"
          dangerouslySetInnerHTML={{
            __html: profile.data.body.html,
          }}
        />
      )}
    </div>
  )
}
